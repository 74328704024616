// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppModules }   from '@/Constants/AppModules'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Namespace Export
export namespace View41DataParsers {
	export function AddonsParser(data: Array<any>, includeStorages = true, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idCheckListAddons', 'storages')

		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign(null, 'center').setSearchKey('code')
			.add('addonName', 'Descripción').isSortable().setSearchKey('name')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
			.add('addonAttributes', 'Preguntas').setSearchKey('attributes')
			.add('mStorages', 'Plantas').setSearchKey('company.name')
			.add('addonIsForClient', 'Exclusivo Cliente').setAlign(null, 'center').setSearchKey('isForClient')
		)

		// Eliminar los campos que no queremos mostrar en la tabla.
		if (!includeStorages) bm.removeFields('mStorages')

		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'addonAttributes', 'mStorages', 'addonIsForClient')

		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('mStorages')
		)

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			addonsAttributes: (values: Array<string>) => {
				return values.sort()
			},

			addonGroupArticle: (value: string) => {
				return value === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
					? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase()
					: value
			},

			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			mStorages: (_: void, item: any) => {
				if (Array.isArray(item.storages) && item.storages.length > 0) {
					return item.storages.map((x: any) => x.storageName)
				}
				return AppValues.Strings.DEFAULT_EMPTY_STRING
			},
		})

		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}

	export function AddonsAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idCheckListAddons', '_idStorage', '_idCompany', 'addonIsForClient', 'isForClient')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign('center', 'center').setSearchKey('code')
			.add('addonName', 'Descripción').isSortable().setAlign('center', 'center').setSearchKey('name')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().setAlign('center', 'center').setSearchKey('typeEquipment')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().setAlign('center', 'center').setSearchKey('groupArticle')
			.add('storageCode', 'Código Planta').isSortable().setAlign('center', 'center').setSearchKey('storage.code')
			.add('storageName', 'Planta').isSortable().setAlign('center', null).setSearchKey('storage.name')
			.add('companyName', 'Cliente').isSortable().setAlign('center', null).setSearchKey('company.name')
			.add('addonAttributes', 'Listado Evaluación').setAlign('center', null).setSearchKey('attributes')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'storageCode', 'storageName', 'companyName', 'addonAttributes', 'addonIsForClient')

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			companyName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCode: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.pack()
	}

	export function AddonsStoragesParser(data: Array<any>, _actions?: any) {
		// Clase Constructora.
		const bm = new BuilderManager(data, _actions || undefined)

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idCompany', '_idStorage', '_idTechnical', '_idUserAdmin', 'address', 'code', 'codeLocation', 'commune', 'contacts', 'equipments', 'isValid', 'name', 'region', 'technicalName', 'userAdminName')

		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('storageCode', 'Código').isSortable().setAlign(null, 'center')
			.add('storageName', 'Planta').isSortable()
			.add('storageAddress', 'Dirección').isSortable()
			.add('companyName', 'Cliente').isSortable()
			.add('storageCommune', 'Comuna').setAlign(null, 'center')
		)

		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('storageCode', 'storageName', 'storageAddress', 'companyName', 'storageCommune')

		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('storageCode', '')
			.add('storageName', '')
			.add('storageAddress', '')
			.add('companyName', '')
			.add('storageCommune', '')
		)

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			storageAddress: (value: string, item: any) => {
				return value || item?.address || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCode: (value: string, item: any) => {
				return value || item?.code || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCommune: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageName: (value: string, item: any) => {
				return value || item?.name || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})

		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}

	export function FacilitiesParser(data: Array<any>, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idInstallation', 'typeActive', 'groupArticle', 'storages')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('code', 'Código Instalación').isSortable().setAlign('center', 'center')
			.add('name', 'Descripción').isSortable()
			.add('mStorages', 'Plantas').isSortable().setSearchKey('company.name').setSearchKey('storage.name')
			.add('isValid', '¿Esta Activa?').setAlign('center', 'center').isSortable().setSearchKey('isValid')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('code', 'name', 'mStorages', 'isValid')
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('mStorages')
		)
	
		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			groupArticle: (v: string) => {
				return v === AppValues.ArticleGroups.FACILITY ? 'Instalación' : v
			},
	
			isValid: (v: boolean) => {
				return v ? 'Sí' : 'No'
			},

			mStorages: (_: void, item: any) => {
				if (Array.isArray(item.storages) && item.storages.length > 0) {
					return item.storages.map((x: any) => x.storageName)
				}
				return AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}

	export function FacilityAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idInstallation', '_idStorage', '_idCompany', 'storageCommune')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('installationCode', 'Código').isSortable().setAlign('center', 'center').setSearchKey('code')
			.add('installationName', 'Descripción').isSortable().isSortable().setAlign('center', 'center').setSearchKey('name')
			.add('installationGroupArticle', 'Tipo Activo').isSortable().isSortable().setAlign('center', 'center').setSearchKey('groupArticle')
			.add('storageCode', 'Código Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.code')
			.add('storageName', 'Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.name')
			.add('companyName', 'Cliente').isSortable().isSortable().setAlign('center', 'center').setSearchKey('company.name')
			.add('storageAddress', 'Dirección').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.address.name')
			.add('installationIsValid', '¿Esta Activa?').isSortable().isSortable().setAlign('center', 'center').setSearchKey('isValid')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('installationCode', 'installationName', 'installationGroupArticle', 'storageCode', 'storageName', 'companyName', 'storageAddress', 'installationIsValid')

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			installationGroupArticle: (value: string) => {
				if (!value) return AppValues.Strings.DEFAULT_EMPTY_STRING
				return value.toUpperCase() === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY.toUpperCase()
					? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY
					: value
			},

			installationIsValid: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			storageAddress: (value: boolean) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.pack()
	}
}