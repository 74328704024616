// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'

// Constants
import { ActionFields } from '@/Constants/ActionFields'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Function Export
export function parseItemsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.EditDelete)

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('num', '#').isSortable().setAlign('center', 'center').setStyleTh({ width: '64px' })
		.add('name', 'Descripción').isSortable().isSortable().setAlign('center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('num', 'name')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('num', { $text: 'AUTO_INCREMENT' })
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}